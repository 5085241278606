<template>
  <div class="mt-3">
    <div
      style="border: 1px #ddd solid"
      class="px-2 pt-4 pb-2 rounded-lg relative"
    >
      <span
        style="top: -12px; left: 0px"
        class="
          ml-3
          px-1
          absolute
          white
          font-weight-bold
          primary--text
          text--darken-1
          white
          subtitle-1
        "
        >{{ title }}</span
      >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
