<template>
  <div
    class="train-bots d-flex justify-center"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-10'"
  >
    <div :class="$vuetify.breakpoint.smAndDown ? 'w-100' : 'w-80'">
      <Bots
        :botHeader="'Select Bot and Customize Your Conversations'"
        :class="$vuetify.breakpoint.smAndDown ? 'd-none' : ''"
        :botForm="botTemplate"
        @toggle-changed="save"
        :toggleLoading="botToggleLoading"
      />

      <v-divider
        class="my-8"
        :class="$vuetify.breakpoint.smAndDown ? 'd-none' : ''"
      ></v-divider>

      <div :class="$vuetify.breakpoint.smAndDown ? '' : 'd-none'">
        <v-switch
          v-if="
            getCurrentRoute == 'DashboardManageBots' ||
            getCurrentRoute == 'DashboardTrainBots'
          "
          v-model="botTemplate.toggle"
          dense
          :label="`Bot ${botStatus}`"
          :color="botTemplate && botTemplate.toggle ? 'success' : 'error'"
          @change="save"
          :loading="botToggleLoading"
          class="mt-0"
        ></v-switch>
      </div>

      <LoadingComponent v-if="isLoading" />

      <v-expansion-panels v-model="panelOpened" v-else>
        <h3 class="mr-auto" v-if="getCurrentRoute == 'DashboardManageBots'">
          Settings
        </h3>

        <v-expansion-panel
          v-for="(item, i) in settingsItem"
          :key="i"
          v-if="getCurrentRoute == 'DashboardManageBots'"
        >
          <v-expansion-panel-header v-if="shouldRenderHeader(item)">
            <div class="d-flex">
              <v-icon x-large color="#27272A" class="pr-4">{{
                item.icon
              }}</v-icon>
              <div class="">
                <h3 class="mb-1">{{ item.title }}</h3>
                <p class="font-weight-medium" style="font-size: 12px">
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="i == 0">
              <div :class="$vuetify.breakpoint.smAndDown ? 'd-block' : ''">
                <v-row>
                  <v-col cols="12" lg="7" md="7">
                    <div
                      class="flex-6"
                      :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
                    >
                      <div class="d-flex">
                        <div class="avatar-container pointer">
                          <v-avatar size="30" class="edit-avatar">
                            <!-- <fa-icon icon="pen" /> -->
                            <v-file-input
                              :color="'#FFFFFF'"
                              flat
                              prepend-icon="mdi-pencil"
                              hide-input
                              :rules="rules"
                              accept="image/png, image/jpeg, image/bmp"
                              class="pt-0 mt-0"
                              @change="handleImageUpload"
                            ></v-file-input>
                          </v-avatar>
                          <div v-if="!botTemplate['icon']">
                            <v-img
                              :src="previewImage"
                              width="60"
                              height="60"
                              v-if="previewImage"
                              contain
                            ></v-img>
                            <v-avatar rounded size="60" color="#E9EBEE" v-else>
                              <v-icon size="60" color="grey" dark class="pa-3">
                                mdi-robot-outline
                              </v-icon>
                            </v-avatar>
                          </div>
                          <div v-else>
                            <v-img
                              :src="botTemplate['icon']"
                              width="60"
                              height="60"
                              contain
                            ></v-img>
                          </div>
                        </div>
                        <div class="profile-info">
                          <v-text-field
                            dense
                            hide-details
                            class="flex-grow-1 mb-0"
                            label="Bot Name"
                            outlined
                            style="width: 200px"
                            v-model="botTemplate['name']"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="color-settings pt-5 mb-5">
                        <v-row>
                          <v-col cols="12" lg="6" md="6">
                            <div class="d-flex align-center">
                              <div
                                class="color-picker"
                                :style="`background:${botTemplate['ai_text_color']}`"
                                @click.stop="aiTextColorShow = !aiTextColorShow"
                              ></div>
                              <div class="ml-2">AI Text Color</div>
                            </div>
                            <v-color-picker
                              class="color-picker-popup"
                              v-show="aiTextColorShow"
                              v-model="botTemplate['ai_text_color']"
                              flat
                            ></v-color-picker>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <div class="d-flex align-center">
                              <div
                                class="color-picker"
                                :style="`background:${botTemplate['ai_bg_color']}`"
                                @click.stop="aiBgColorShow = !aiBgColorShow"
                              ></div>
                              <div class="ml-2">AI Background Color</div>
                            </div>
                            <v-color-picker
                              class="color-picker-popup"
                              v-show="aiBgColorShow"
                              v-model="botTemplate['ai_bg_color']"
                              flat
                            ></v-color-picker>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" lg="6" md="6">
                            <div class="d-flex align-center">
                              <div
                                class="color-picker"
                                :style="`background:${botTemplate['user_text_color']}`"
                                @click.stop="
                                  userTextColorShow = !userTextColorShow
                                "
                              ></div>
                              <div class="ml-2">User Text Color</div>
                            </div>
                            <v-color-picker
                              class="color-picker-popup"
                              v-show="userTextColorShow"
                              v-model="botTemplate['user_text_color']"
                              flat
                            ></v-color-picker>
                          </v-col>

                          <v-col cols="12" lg="6" md="6">
                            <div class="d-flex align-center">
                              <div
                                class="color-picker"
                                :style="`background:${botTemplate['user_bg_color']}`"
                                @click.stop="userBgColorShow = !userBgColorShow"
                              ></div>
                              <div class="ml-2">User Background Color</div>
                            </div>
                            <v-color-picker
                              class="color-picker-popup"
                              v-show="userBgColorShow"
                              v-model="botTemplate['user_bg_color']"
                              flat
                            ></v-color-picker>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- <v-divider class="my-6"></v-divider> -->
                    </div>

                    <!-- <v-divider vertical="false"></v-divider>

                <div
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
                  style="flex: 1"
                >
                  <h4 class="mb-3">
                    Copy the bot's link, test its capabilities, and make waves
                    among your friends and satisfied customers!
                  </h4>

                  <v-textarea
                    dark
                    v-model="botUrlComputed"
                    solo
                    rows="5"
                    dense
                    hide-details
                    no-resize
                  ></v-textarea>

                  <div class="d-flex align-center">
                    <v-btn
                      :disabled="!botUrlComputed"
                      v-clipboard="() => botUrlComputed"
                      @success="copy('bot_url')"
                      color="primary"
                      class="mt-2"
                    >
                      Copy URL
                    </v-btn>

                    <v-btn
                      depressed
                      text
                      class="ml-2 mt-2"
                      to="/dashboard/help/embed-url"
                      color="primary"
                      >Learn More</v-btn
                    >
                  </div>
                </div> -->
                  </v-col>

                  <v-col cols="12" lg="5" md="5">
                    <div class="pa-5">
                      <div class="preview pa-5">
                        <h4 class="mb-2">Chat Bubble Preview</h4>
                        <div class="mb-2">
                          <span
                            style="font-size: 13px"
                            class="font-weight-bold ml-4"
                            >AI</span
                          >
                          <div
                            class="pa-4 bubble"
                            style="font-size: 12px"
                            :style="`color:${botTemplate['ai_text_color']}; background: ${botTemplate['ai_bg_color']}`"
                          >
                            Text here
                          </div>
                        </div>

                        <div class="text-right">
                          <div class="mr-4">
                            <span
                              style="font-size: 13px"
                              class="font-weight-bold"
                              >User</span
                            >
                          </div>

                          <div
                            class="pa-4 bubble"
                            style="font-size: 12px"
                            :style="`color:${botTemplate['user_text_color']}; background: ${botTemplate['user_bg_color']}`"
                          >
                            Text here
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <div class="d-block pa-5 pt-0">
                  <v-row>
                    <v-col cols="12">
                      <div class="description">
                        <v-textarea
                          dense
                          hide-details
                          class="mb-0"
                          label="Bot Description"
                          outlined
                          rows="2"
                          v-model="botTemplate['description']"
                        ></v-textarea>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="greeting-msg">
                        <v-text-field
                          dense
                          hide-details
                          class="mb-0"
                          label="Greeting Message"
                          outlined
                          v-model="botTemplate['greeting_message']"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="tone">
                        <v-textarea
                          dense
                          hide-details
                          class="mb-0"
                          label="Tone"
                          outlined
                          rows="5"
                          v-model="botTemplate['tone']"
                        ></v-textarea>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="standby">
                        <v-textarea
                          dense
                          hide-details
                          class="mb-0"
                          label="Standby Message"
                          outlined
                          rows="2"
                          v-model="botTemplate['standby_message']"
                        ></v-textarea>
                      </div>

                      <v-btn
                        :loading="isSaving"
                        :disabled="isSaving || !hasChanges"
                        class="mt-4 text-capitalize"
                        @click="save"
                        color="primary"
                        >Save</v-btn
                      >
                    </v-col>

                    <!-- <v-col cols="12">
                      <div class="tone-msg">
                        <v-textarea
                          dense
                          hide-details
                          class="mb-0"
                          label="Tone Message"
                          outlined
                          rows="2"
                          v-model="botTemplate['tone']"
                        ></v-textarea>
                      </div>

                      <v-btn
                        :loading="isSaving"
                        :disabled="isSaving || !hasChanges"
                        class="mt-4 text-capitalize"
                        @click="save"
                        color="primary"
                        >Save</v-btn
                      >
                    </v-col> -->
                  </v-row>
                </div>
              </div>
            </div>
            <div v-if="i == 1 && shouldRenderContent(item)">
              <div
                class="pt-3 mb-4"
                :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
              >
                <Contact />
              </div>
            </div>
            <div v-if="i == 2 && shouldRenderContent(item)">
              <div
                class="pt-3 mb-4"
                :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
              >
                <Delegation />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <h3
          class="mr-auto pt-2"
          v-if="
            (activeBot.role_data == 'Owner' ||
              activeBot.role_data == 'Editor') &&
            getCurrentRoute == 'DashboardTrainBots'
          "
        >
          Train
        </h3>

        <TrainBot
          v-if="
            (activeBot.role_data == 'Owner' ||
              activeBot.role_data == 'Editor') &&
            getCurrentRoute == 'DashboardTrainBots'
          "
        />

        <h3
          class="mr-auto pt-2"
          v-if="getCurrentRoute == 'DashboardManageBots'"
        >
          Deploy
        </h3>

        <v-expansion-panel
          :id="`expansion-deploy-${i}`"
          v-for="(item, i) in deployItem"
          :key="'A' + i"
          v-if="getCurrentRoute == 'DashboardManageBots'"
        >
          <v-expansion-panel-header>
            <div class="d-flex">
              <v-icon x-large color="#27272A" class="pr-4">{{
                item.icon
              }}</v-icon>
              <div class="">
                <h3 class="mb-1">{{ item.title }}</h3>
                <p class="font-weight-medium" style="font-size: 12px">
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="i == 0">
              <div class="mb-3">
                <div
                  class="pt-3 mb-4"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <!-- <v-textarea
                    dark
                    v-model="botUrlComputed"
                    solo
                    rows="2"
                    dense
                    hide-details
                    no-resize
                  ></v-textarea> -->
                  <h3>Coming Soon</h3>
                </div>
                <!-- <div
                  class="d-flex w-100 mb-3"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <v-btn
                    :disabled="!botUrlComputed"
                    v-clipboard="() => botUrlComputed"
                    @success="copy('bot_url')"
                    color="primary"
                    class="mt-2"
                  >
                    Copy URL
                  </v-btn>

                  <v-btn
                    depressed
                    text
                    class="ml-2 mt-2"
                    to="/dashboard/help/embed-url"
                    color="primary"
                    >Learn More</v-btn
                  >
                </div> -->
              </div>
            </div>

            <div v-if="i == 1">
              <div class="mb-3">
                <div
                  class="pt-3 mb-4"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <v-textarea
                    dark
                    v-model="botUrlComputed"
                    solo
                    rows="2"
                    dense
                    hide-details
                    no-resize
                  ></v-textarea>
                </div>
                <div
                  class="d-flex w-100 mb-3"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <v-btn
                    :disabled="!botUrlComputed"
                    v-clipboard="() => botUrlComputed"
                    @success="copy('bot_url')"
                    color="primary"
                    class="mt-2"
                  >
                    Copy URL
                  </v-btn>

                  <v-btn
                    depressed
                    text
                    class="ml-2 mt-2"
                    to="/dashboard/help/embed-url"
                    color="primary"
                    >Learn More</v-btn
                  >
                </div>
              </div>
            </div>

            <div v-if="i == 2">
              <div class="mb-3">
                <div
                  class="d-flex align-center w-100"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <p class="mb-2">
                    Copy and paste this code on your website to embed this
                    Chatbot.<br /><em
                      >After pasting the code, then edit the properties to fit
                      your site.</em
                    >
                  </p>
                </div>
                <div
                  class="pt-3 mb-4"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <v-textarea
                    dark
                    v-model="embedComputed"
                    solo
                    rows="3"
                    dense
                    hide-details
                  ></v-textarea>
                </div>
                <div
                  class="d-flex w-100 mb-3"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'px-4'"
                >
                  <v-btn
                    :disabled="!embedComputed"
                    v-clipboard="() => embedComputed"
                    @success="copy('embed')"
                    color="primary"
                  >
                    Copy Code
                  </v-btn>

                  <v-btn
                    depressed
                    text
                    class="ml-2"
                    to="/dashboard/help/chatbot-url"
                    color="primary"
                    >Learn More</v-btn
                  >
                </div>
              </div>
            </div>

            <div v-if="i == 3">
              <LabelBox class="mt-6" title="Meta Messenger Settings">
                <template>
                  <div class="mb-2 ma-2">
                    <h4 class="mb-2">
                      Copy the email and add as an admin/user to your meta
                      facebook page
                    </h4>

                    <v-text-field
                      dense
                      hide-details
                      class="flex-grow-1 mb-4"
                      label="Pinoybot AI email"
                      outlined
                      readonly
                      v-model="adminEmail"
                      append-icon=""
                    >
                      <template v-slot:append>
                        <v-icon
                          v-clipboard="() => adminEmail"
                          @success="copy('admin AI email')"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      ></v-text-field
                    >

                    <v-divider class="my-4"></v-divider>

                    <h4 class="mb-2">
                      Click the "Connect to Messenger" button to generate the
                      page ID from your meta messenger settings
                      <!-- Paste the page ID and messenger key from your meta
                      messenger settings -->
                    </h4>
                    <div
                      class="d-flex align-center mb-4"
                      style="flex-wrap: wrap"
                    >
                      <div
                        class="flex-grow-1 mr-1 relative"
                        id="page-id-selection"
                      >
                        <v-select
                          v-if="pageIdSelection.length"
                          attach="#page-id-selection"
                          dense
                          v-model="botTemplate['page_id']"
                          :items="pageIdSelection"
                          outlined
                          label="Page ID"
                          item-text="page_id"
                          item-value="page_id"
                          hide-details
                          ref="page-selection"
                        >
                          <template v-slot:item="{ item }">
                            <div>{{ pageSelection(item) }}</div>
                          </template>
                        </v-select>
                        <v-text-field
                          v-else
                          dense
                          hide-details
                          class="flex-grow-1 mr-1"
                          label="Page ID"
                          outlined
                          v-model="botTemplate['page_id']"
                        ></v-text-field>
                      </div>
                      <v-btn
                        class="text-capitalize"
                        @click="openAuth"
                        color="primary"
                        >Continue with Facebook</v-btn
                      >
                    </div>
                    <!-- <pre>{{ pageIdSelection }}</pre> -->

                    <!-- <h4 class="mb-2">Messenger Key</h4>
                    <v-text-field
                      dense
                      hide-details
                      class="flex-grow-1 mb-4"
                      label="Messenger Key"
                      outlined
                      v-model="botTemplate['messenger_key']"
                    ></v-text-field> -->

                    <!-- <h4 class="mb-2">Callback URL</h4>
                    <v-text-field
                      dense
                      hide-details
                      class="flex-grow-1 mb-4"
                      label="Callback URL"
                      outlined
                      v-model="botTemplate['callback_url']"
                    ></v-text-field> -->

                    <!-- <v-divider class="my-4"></v-divider> -->

                    <!-- <v-text-field
                      dense
                      hide-details
                      class="flex-grow-1 mb-4"
                      label="Webhook token"
                      outlined
                      readonly
                      v-model="botTemplate['webhook_token']"
                      append-icon=""
                    >
                      <template v-slot:append>
                        <v-icon
                          v-clipboard="() => botTemplate['webhook_token']"
                          @success="copy('webhook')"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      ></v-text-field
                    > -->
                  </div>
                </template>
              </LabelBox>

              <div class="d-flex align-center">
                <v-btn
                  :loading="isSaving"
                  :disabled="isSaving || !hasChanges"
                  class="mt-4 text-capitalize"
                  @click="save"
                  color="primary"
                  >Save</v-btn
                >

                <v-btn
                  depressed
                  text
                  class="ml-2 mt-4"
                  to="/dashboard/help/messenger"
                  color="primary"
                  >Learn More</v-btn
                >
              </div>
            </div>

            <div v-if="i == 4" class="d-flex align-center">
              <v-select
                v-model="botTemplate['language']"
                :items="languageOptions"
                label="Select Language"
                outlined
                dense
                hide-details
                class="mr-2"
              ></v-select>

              <v-btn
                :loading="isSaving"
                :disabled="isSaving || !hasChanges"
                class="text-capitalize"
                @click="save"
                color="primary"
                >Save</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <h3
          class="mr-auto pt-2"
          v-if="
            activeBot.role_data == 'Owner' &&
            getCurrentRoute == 'DashboardManageBots'
          "
        >
          Delete
        </h3>
        <v-expansion-panel
          v-for="(item, i) in deleteItem"
          :key="'B' + i"
          v-if="
            activeBot.role_data == 'Owner' &&
            getCurrentRoute == 'DashboardManageBots'
          "
        >
          <v-expansion-panel-header>
            <div class="d-flex">
              <v-icon x-large color="#27272A" class="pr-4">{{
                item.icon
              }}</v-icon>
              <div class="">
                <h3 class="mb-1">{{ item.title }}</h3>
                <p class="font-weight-medium" style="font-size: 12px">
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="i == 0">
              <div :class="$vuetify.breakpoint.smAndDown ? 'd-block' : ''">
                <div
                  class="flex-6"
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
                >
                  <p class="font-weight-medium mb-4">
                    Deleting your chatbot will remove all of your bot
                    information, trains, settings from our database. <br />
                    This cannot be undone.
                  </p>

                  <span>To confirm this, type "DELETE"</span>
                  <div class="d-flex mt-2 align-center">
                    <div class="delete-field mr-2">
                      <v-text-field
                        dense
                        v-model="botDelete"
                        hide-details
                        class="flex-grow-1 mb-0"
                        style="width: 200px"
                        outlined
                      ></v-text-field>
                    </div>
                    <v-btn
                      class="text-capitalize"
                      color="secondary"
                      :disabled="botDelete != 'DELETE'"
                      @click="deleteChatbot"
                      >Delete Chatbot</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Bots from "@/components/bot_profile/bots.vue";
import LoadingComponent from "@/components/helpers/Loading";
import LabelBox from "@/components/helpers/LabelBox";
import TrainBot from "@/components/dashboard/TrainBots.vue";
import Contact from "@/components/contact/contacts.vue";
import Delegation from "@/components/delegation/delegate.vue";

import Utils from "@/helpers/utils";
import router from "@/router";

export default {
  components: {
    Bots,
    LoadingComponent,
    LabelBox,
    TrainBot,
    Contact,
    Delegation,
  },
  data() {
    return {
      panelOpened: null,
      rules: [(value) => !!value || "Please select an image."],
      previewImage: null,

      settingsItem: [
        {
          title: "Edit Chatbot",
          desc: "Personalize and tailor the chatbot's appearance and behavior to suit specific preferences and needs.",
          icon: "mdi-circle-edit-outline",
        },
        {
          title: "Emails and Phone Numbers",
          desc: "Set up your notification preferences by adding numbers and emails to ensure timely and effective communication.",
          icon: "mdi-phone",
        },
        {
          title: "Delegate Access",
          desc: "Delegate the access and define the role via sending an invitation to a user via email.",
          icon: "mdi-security",
        },
      ],

      deployItem: [
        {
          title: "Bot Email Extension",
          desc: "Experience the future of email management with our cutting-edge Bot Email Extension, a must-have tool exclusively for Chrome users!",
          icon: "mdi-email",
        },
        {
          title: "Bot URL",
          desc: "Copy the bot's link, test its capabilities, and make waves among your friends and satisfied customers!",
          icon: "mdi-xml",
        },
        {
          title: "Embed on Web",
          desc: "Integrate the chatbot seamlessly into websites or applications for a smooth user experience.",
          icon: "mdi-web",
        },
        {
          title: "Connect To Messenger",
          desc: "Configure the chatbot's interactions and responses on social media platforms to engage with users effectively.",
          icon: "mdi-facebook-messenger",
        },
      ],

      deleteItem: [
        {
          title: "Delete Chatbot",
          desc: "Deletion of chatbot includes all informations, trains and settings",
          icon: "mdi-delete-outline",
        },
      ],

      aiTextColorShow: false,
      aiBgColorShow: false,
      userTextColorShow: false,
      userBgColorShow: false,
      toneShow: false,

      baseBot: {},
      botTemplate: {},
      adminEmail: "ai@myndconsulting.com",
      isSaving: false,
      isLoading: false,

      embedCode: "",
      botUrl: "",

      botDelete: null,

      languageOptions: [
        { text: "Filipino", value: "Filipino" },
        { text: "English", value: "English" },
        { text: "Spanish", value: "Spanish" },
        { text: "French", value: "French" },
        { text: "German", value: "German" },
        // Add more languages here
      ],
      pageIdSelection: [],
      botToggleLoading: false,
    };
  },

  computed: {
    ...mapGetters("bots", ["activeBot"]),
    ...mapGetters("auth", ["currentUser"]),

    hasBotChanges() {
      return !_.isEqual(this.botTemplate, this.baseBot);
    },
    hasChanges() {
      return this.hasBotChanges;
    },
    embedComputed: {
      get() {
        return this.embedCode;
      },
      set(v) {
        this.embedCode = v;
      },
    },

    botUrlComputed: {
      get() {
        return this.botUrl;
      },
      set(v) {
        this.botUrl = v;
      },
    },

    fullPlaygroundRouteURL() {
      const routeName = "DashboardPlayground";

      const route = router.resolve({
        name: routeName,
      });

      return (
        "<iframe src='" +
        window.location.origin +
        route.href +
        "/" +
        this.activeBot?.id +
        "/embed' width='850' height='1000' scrolling='yes' style='overflow:hidden;border:none;'></iframe>"
      );
    },

    generateBotURL() {
      const routeName = "DashboardPlayground";

      const route = router.resolve({
        name: routeName,
      });

      return (
        window.location.origin +
        route.href +
        "/" +
        this.activeBot?.id +
        "/play-and-share"
      );
    },

    paramBotId() {
      return this.$route.params?.botId;
    },

    getCurrentRoute() {
      return this.$route.name;
    },

    botStatus() {
      return this.activeBot.toggle ? "Activated" : "Deactivated";
    },
  },

  watch: {
    currentUser: {
      immediate: true,
      handler(n) {
        if (_.isEmpty(n)) {
          return;
        }
        this.initActiveBot();
        this.getBotDelegatedAccounts();
      },
    },

    activeBot: {
      immediate: false,
      handler(v) {
        this.isLoading = true;
        setTimeout(() => {
          this.initActiveBot();
          this.isLoading = false;
          this.initQuery();
        }, 500);
        //dynamic embed code from playground
        this.initEmbedCode();
        this.initBotUrl();
        this.getBotDelegatedAccounts();
      },
    },
  },

  methods: {
    ...mapActions("bots", ["updateBot", "deleteBot", "getBots"]),
    ...mapActions("user", ["getDelegatedAccounts"]),
    ...mapMutations("user", ["SET_DELEGATED_ACCOUNTS"]),
    ...mapActions("bots", [
      "updateBot",
      "deleteBot",
      "getBots",
      "openAuthFacebook",
    ]),
    ...mapMutations("bots", ["setActiveBot", "setContact"]),

    handleImageUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.botTemplate["icon"] = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        if (this.botTemplate["icon"] == null) {
          this.previewImage = null;
        } else {
          this.previewImage = this.botTemplate["icon"];
        }
      }
    },

    initActiveBot() {
      let botTemplate = _.cloneDeep(this.activeBot);
      this.baseBot = _.cloneDeep(botTemplate);
      this.botTemplate = botTemplate;
    },

    initEmbedCode() {
      this.embedCode = this.fullPlaygroundRouteURL;
    },

    initBotUrl() {
      this.botUrl = this.generateBotURL;
    },

    save() {
      this.updateChatBotSetting(this.botTemplate);
    },

    async updateChatBotSetting(form) {
      try {
        this.isSaving = true;
        this.botToggleLoading = true;
        await this.updateBot({
          id: this.activeBot.id, //todo get careplan id
          data: form,
        })
          .then(
            (res) => {
              globalMessageHandler("Successfully Updated.");
              this.setActiveBot(res.data);
              this.setContact({
                emails: res.data?.emails,
                mobile_numbers: res.data?.mobile_numbers,
              });
              this.getBots(this.paramBotId);
              this.isSaving = false;
              this.botToggleLoading = false;
            },
            (err) => {
              globalErrorHandler(err, "Error updating bot!");
            }
          )
          .finally(() => {
            this.isSaving = false;
            this.botToggleLoading = false;
            this.show = false;
          });
      } catch (error) {
        globalErrorHandler(error, "Unable to save profile.");
      } finally {
        this.isSaving = false;
        this.botToggleLoading = false;
      }
    },

    deleteChatbot(item) {
      Utils.showMessageDefault({
        html: `Are you sure you want to delete this chatbot?`,
        confirmButtonColor: "#e05a4c",
        confirmButtonTextColor: "white",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((res) => {
        if (res.isConfirmed) {
          this.deleteBot({
            botId: this.activeBot.id,
          });
          setTimeout(() => {
            // window.location.href = 'http://localhost:8080/dashboard/manage-bots'
            window.location.href = router.resolve({
              name: "DashboardSettings",
            }).href;
            globalMessageHandler("Successfully Deleted.");
          }, 500);
        }
      });
    },

    copy(v) {
      if (v == "embed") {
        globalMessageHandler(`The Chatbot widget has been copied!`);
      } else if (v == "bot_url") {
        globalMessageHandler(`The Bot URL has been copied!`);
      } else {
        globalMessageHandler(
          `The Meta messenger settings on ${v} has been copied!`
        );
      }
    },

    async getBotDelegatedAccounts() {
      this.getDelegatedAccounts(this.activeBot.id).then((res) => {
        this.SET_DELEGATED_ACCOUNTS(res.data);
      });
    },

    openAuth() {
      this.openAuthFacebook().then(
        (res) => {
          this.openUrl(res.data);
        },
        (err) => {}
      );
    },
    openUrl(url) {
      window.open(url, "_self");
    },
    pageSelection(item) {
      return `${item.page_id} - ${item.name}`;
    },
    initQuery() {
      let _query = this.$route.query;
      if (!_query.pages) return;
      try {
        let _queryParse = JSON.parse(_query.pages);
        this.pageIdSelection = _queryParse;
      } catch (error) {
        this.pageIdSelection = [];
      }
    },

    shouldRenderHeader(item) {
      return (
        (item.title !== "Delegate Access" &&
          item.title !== "Emails and Phone Numbers") ||
        this.activeBot.role_data === "Owner" ||
        this.activeBot.role_data === "Editor"
      );
    },
    shouldRenderContent(item) {
      return (
        (item.title !== "Delegate Access" &&
          item.title !== "Emails and Phone Numbers") ||
        this.activeBot.role_data === "Owner" ||
        this.activeBot.role_data === "Editor"
      );
    },
  },

  mounted() {
    this.initEmbedCode();
    this.initBotUrl();
  },

  beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      Utils.showMessageDefault({
        html: `Do you want to leave this page and discard your changes?`,
        text: null,
        title: "Discard Changes",
        confirmButtonColor: "#e05a4c",
        confirmButtonTextColor: "white",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((res) => {
        if (res.isConfirmed) {
          next();
        }
      });
    } else next();
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  display: inline-block;
  position: relative;
  border: 1px solid rgba(44, 62, 80, 0.2);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  img {
    object-fit: cover;
  }

  .edit-avatar {
    top: -12px;
    right: -12px;
    position: absolute;
    color: white;
    background: #fff;
    border: 1px solid lightgray;
    padding-left: 3px;
    z-index: 1;
  }
}

button.v-icon.notranslate.v-icon--link.mdi.mdi-pencil.theme--light {
  color: #fff !important;
}

.profile-info {
  display: inline-block;
  vertical-align: top;
  margin-left: 23px;
}

.color-settings {
  .color-picker {
    border: 1px solid #272727;
    border-radius: 5px;
    position: relative;
    height: 30px;
    width: 30px;
  }

  .color-picker-popup {
    margin-top: 5px;
    position: absolute;
    width: 300px;
    border-radius: 6px;
    background: #fff !important;
    z-index: 1;
    border: 1px solid #00000033;
  }
}

.v-select-list {
  margin-top: 40px;
}

.preview {
  border: 1px solid #ececec;
  border-radius: 5px;

  .bubble {
    border-radius: 8px;
  }
}
</style>
