<template>
  <div class="train-bots">
    <!-- <div :class="$vuetify.breakpoint.smAndDown ? 'w-100' : 'w-80'">
      <Bots :botHeader="'Select and Train your bots'" />

      <v-divider class="my-8"></v-divider> -->
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in items" :key="i" @click="setActiveTypeHistory(item.type)">
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon x-large color="#27272A" class="pr-4">{{
              item.icon
            }}</v-icon>
            <div class="">
              <h3 class="mb-1">{{ item.title }}</h3>
              <p class="font-weight-medium" style="font-size: 12px">
                {{ item.desc }}
              </p>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="item.type == TYPE_QNA">
            <div
              class="pt-0"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
            >
              <div class="history text-right mb-4">
                <v-btn
                  class="text-capitalize mr-2"
                  small
                  color="green"
                  outlined
                  @click="setShowSample(true), setActiveSample('sample_chat')"
                  >Show Example</v-btn
                >
                <v-btn
                  class="text-capitalize"
                  small
                  color="primary"
                  outlined
                  @click="
                    setShowTrainingHistory(true), setActiveTypeHistory(TYPE_QNA)
                  "
                  >History</v-btn
                >
              </div>
              <div class="question-msg mb-4">
                <v-text-field
                  dense
                  class="mb-0"
                  label="Question"
                  hide-details
                  outlined
                  v-model="trainChatQnA.question"
                  placeholder="Type your question here..."
                ></v-text-field>
              </div>
              <div class="answer-msg mb-4">
                <v-textarea
                  outlined
                  dense
                  label="Answer"
                  hide-details
                  v-model="trainChatQnA.answer"
                  placeholder="Type your answer here..."
                ></v-textarea>
              </div>
              <v-btn
                class="text-capitalize"
                color="primary"
                @click="
                  trainChatQnA && trainChatQnA.id
                    ? updateTrain(TYPE_QNA)
                    : submitTrain(TYPE_QNA)
                "
                :loading="isSaving"
                :disabled="isSaving || isButtonDisabledQnA"
                >{{ trainChatQnA && trainChatQnA.id ? "Update" : "Submit" }}</v-btn
              >
              <v-btn
                v-if="trainChatQnA && trainChatQnA.id"
                class="text-capitalize ml-3"
                @click="emptyTrain(TYPE_QNA)"
                >Cancel</v-btn
              >
            </div>
          </div>
          <div v-if="item.type == TYPE_TEXT">
            <div
              class="pt-0"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
            >
              <div class="history text-right mb-4">
                <v-btn
                  class="text-capitalize mr-2"
                  small
                  color="green"
                  outlined
                  @click="setShowSample(true), setActiveSample('sample_text')"
                  >Show Example</v-btn
                >
                <v-btn
                  class="text-capitalize"
                  small
                  color="primary"
                  outlined
                  @click="
                    setShowTrainingHistory(true), setActiveTypeHistory(TYPE_TEXT)
                  "
                  >History</v-btn
                >
              </div>
              <div class="text-msg mb-4">
                <v-textarea
                  outlined
                  dense
                  label="Text"
                  v-model="trainChatText.text"
                  placeholder="Type your text here..."
                  hide-details
                ></v-textarea>
              </div>
              <v-btn
                class="text-capitalize"
                color="primary"
                @click="
                  trainChatText && trainChatText.id
                    ? updateTrain(TYPE_TEXT)
                    : submitTrain(TYPE_TEXT)
                "
                :loading="isSaving"
                :disabled="isSaving || isButtonDisabledText"
                >{{ trainChatText && trainChatText.id? "Update" : "Submit" }}</v-btn
              >
              <v-btn
                v-if="trainChatText && trainChatText.id"
                class="text-capitalize ml-3"
                @click="emptyTrain(TYPE_TEXT)"
                >Cancel</v-btn
              >
            </div>
          </div>
          <div v-if="item.type == TYPE_FILE">
            <div
              class="pt-0 file-train"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
            >
              <div class="d-flex justify-space-between align-center mb-4">
                <span style="font-size: 11px" class="font-weight-medium"
                  >Accepted file formats: docx, text, Excel sheets, PowerPoint
                  presentations, PDF.</span
                >
                <div class="history text-right">
                  <v-btn
                    class="text-capitalize mr-2"
                    small
                    color="green"
                    outlined
                    @click="setShowSample(true), setActiveSample('sample_pdf')"
                    >Show Example</v-btn
                  >
                  <v-btn
                    class="text-capitalize"
                    small
                    color="primary"
                    outlined
                    @click="
                      setShowTrainingHistory(true), setActiveTypeHistory(TYPE_FILE)
                    "
                    >History</v-btn
                  >
                </div>
              </div>

              <div class="file">
                <div class="title mb-4">
                  <v-text-field
                    dense
                    class="mb-0"
                    label="Title"
                    hide-details
                    outlined
                    v-model="trainChatFile.title"
                    placeholder="Type your title here..."
                  ></v-text-field>
                </div>
                <div
                  v-if="trainChatFile && trainChatFile.id && viewFileOnly"
                  class="mb-3"
                >
                  <span
                    class="text-white pa-1 rounded"
                    style="background-color: #4285f3"
                    >{{ viewFileData }}</span
                  >
                </div>
                <v-file-input
                  v-else
                  accept=".pdf, .docx, .txt, .xls, .xlsx, .ppt, .pptx"
                  v-model="trainChatFile.file"
                  color="primary"
                  counter
                  label="Upload File"
                  outlined
                  :show-size="1000"
                  dense
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ trainChatFile.file.length - 2 }} File
                    </span>
                  </template>
                </v-file-input>
              </div>
              <v-btn
                class="text-capitalize"
                color="primary"
                @click="
                  trainChatFile && trainChatFile.id
                    ? updateTrain(TYPE_FILE)
                    : submitTrain(TYPE_FILE)
                "
                :loading="isSaving"
                :disabled="isSaving || isButtonDisabledFile"
                >{{ trainChatFile && trainChatFile.id? "Update" : "Submit" }}</v-btn
              >
              <v-btn
                v-if="trainChatFile && trainChatFile.id"
                class="text-capitalize ml-3"
                @click="emptyTrain(TYPE_FILE)"
                >Cancel</v-btn
              >
            </div>
          </div>
          <div v-if="item.type == TYPE_URL">
            <div
              class="pt-0"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
            >
              <div class="history text-right mb-4">
                <v-btn
                  class="text-capitalize mr-2"
                  small
                  color="green"
                  outlined
                  @click="setShowSample(true), setActiveSample('sample_url')"
                  >Show Example</v-btn
                >
                <v-btn
                  class="text-capitalize"
                  small
                  color="primary"
                  outlined
                  @click="
                    setShowTrainingHistory(true), setActiveTypeHistory(TYPE_URL)
                  "
                  >History</v-btn
                >
              </div>
              <div class="title mb-4">
                <v-text-field
                  dense
                  class="mb-0"
                  label="Title"
                  hide-details
                  outlined
                  v-model="trainChatUrl.title"
                  placeholder="Type your title here..."
                ></v-text-field>
              </div>

              <div v-if="trainChatUrl && trainChatUrl.id" class="mb-3">
                <span
                  class="text-white pa-1 rounded"
                  style="background-color: #4285f3"
                  >{{ viewUrlData }}</span
                >
              </div>

              <div class="url-msg mb-4" v-else>
                <v-text-field
                  dense
                  class="mb-0"
                  label="URL"
                  hide-details
                  outlined
                  v-model="trainChatUrl.url"
                  placeholder="Paste the URL you'd like to scrape..."
                ></v-text-field>
              </div>
              <v-btn
                class="text-capitalize"
                color="primary"
                @click="
                  trainChatUrl && trainChatUrl.id
                    ? updateTrain(TYPE_URL)
                    : submitTrain(TYPE_URL)
                "
                :loading="isSaving"
                :disabled="isSaving || isButtonDisabledUrl"
                >{{ trainChatUrl && trainChatUrl.id ? "Update" : "Submit" }}</v-btn
              >
              <v-btn
                v-if="trainChatUrl && trainChatUrl.id"
                class="text-capitalize ml-3"
                @click="emptyTrain(TYPE_URL)"
                >Cancel</v-btn
              >
            </div>
          </div>
          <!-- <div v-if="i == 4">
            <div
              class="pt-0"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-5'"
            >
              <div class="url-msg mb-4">
                <h3>Coming Soon..</h3>
              </div>
            </div>
          </div> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Bots from "@/components/bot_profile/bots.vue";
import LoadingComponent from "@/components/helpers/Loading";
import endpoints from "@/routes/endpoints";
import train from "@/modules/train";

let CONST_TYPE_QNA = "qna";
let CONST_TYPE_TEXT = "text";
let CONST_TYPE_FILE = "file";
let CONST_TYPE_URL = "url";

export default {

  name: "trainings",
  components: {
    Bots,
    LoadingComponent,
  },
  data() {
    return {

      TYPE_QNA:CONST_TYPE_QNA,
      TYPE_TEXT:CONST_TYPE_TEXT,
      TYPE_FILE:CONST_TYPE_FILE,
      TYPE_URL:CONST_TYPE_URL,

      items: [
        {
          title: "Train with Chat",
          desc: "Ask a question about your product, service or company and then provide the answer",
          icon: "mdi-chat-outline",
          type: CONST_TYPE_QNA,
        },
        {
          title: "Train with Text",
          desc: "Paste text about your product, service or company to train your bot",
          icon: "mdi-text-box-outline",
          type: CONST_TYPE_TEXT,
        },
        {
          title: "Train with File",
          desc: "Upload a file to train your bot",
          icon: "mdi-file-outline",
          type: CONST_TYPE_FILE,
        },
        {
          title: "Train with URL",
          desc: "Paste the website URL you wish to scrape",
          icon: "mdi-link",
          type: CONST_TYPE_URL,
        },
        // {
        //   title: "Train with Email",
        //   desc: "Ask a question and provide the answer",
        //   icon: "mdi-email-outline",
        // },
      ],
      isLoading: false,
      isSaving: false,

      trainChatQnA:this.getNewTrainChat(CONST_TYPE_QNA),
      trainChatText:this.getNewTrainChat(CONST_TYPE_TEXT),
      trainChatFile:this.getNewTrainChat(CONST_TYPE_FILE),
      trainChatUrl:this.getNewTrainChat(CONST_TYPE_URL),

      viewFileOnly: false,
      viewUrlOnly: false,
      viewFileData: null,
      viewUrlData: null,
    };
  },

  watch: {
    activeBot: {
      immediate: false,
      handler(v) {
        this.isLoading = true;
        setTimeout(() => {
          // this.initActiveBot();
          this.isLoading = false;
        }, 500);
      },
    },
    activeTypeHistory(newType) {      
      console.log(newType);
    },
    // activeTypeHistory: {
    //   immediate: true,
    //   handler(v) {
    //     this.emptyTrain();
    //   },
    // },
  },

  async mounted() {
    this.initBroadcast();
  },

  methods: {
    ...mapActions("train", ["createTrain", "updateTrainItem"]),
    ...mapMutations("train", ["setActiveTypeHistory"]),
    ...mapMutations("modal", [
      "setShowTrainingHistory",
      "setShowSample",
      "setActiveSample",
    ]),

    getNewTrainChat(type = ''){

      var trainChat =
       {
        question: "",
        title: "",
        answer: "",
        text: "",
        url: "",
        file: null,
        type: type,
      }

      return trainChat;
    },

    initBroadcast() {
      BroadcastEvent.$on("TRAIN_EDIT", (val) => {
        this.editPostHandler(val);
      });
    },
    editPostHandler(item) {

      console.log(item);

      if (item.type==this.TYPE_QNA){
        this.trainChatQnA.id=item.id;
        this.trainChatQnA.question = item.question;
        this.trainChatQnA.answer = item.answer;
      }
      if (item.type==this.TYPE_TEXT){
        this.trainChatText.id=item.id;
        this.trainChatText.text = item.title;

        console.log(this.trainChatText);
      }
      if (item.type==this.TYPE_FILE){
        this.trainChatFile.id=item.id;
        this.trainChatFile.title=item.title;
        this.trainChatFile.file=item.object;
        this.viewFileData = item.object;
        this.viewFileOnly = true;
      }
       if (item.type==this.TYPE_URL){
        this.trainChatUrl.id=item.id;
        this.trainChatUrl.title = item.title;
        this.trainChatUrl.url = item.object;
        this.viewUrlData = item.object;
        this.viewUrlOnly = true;
      }
      
    },
    updateTrain(type) {

      var trainChat = null;

      //get the trainChat form base on active type.
      if (type == "qna"){
        trainChat = this.trainChatQnA;
      }

      if (type == "text"){
        trainChat =  this.trainChatText;
      }

      if (type == "file"){
        trainChat = this.trainChatFile;
      }

      if (type == "url"){
        trainChat = this.trainChatUrl;
      }

      this.updateTrainItemData(trainChat);
    },

    updateTrainItemData(form) {
      this.isSaving = true;
      this.updateTrainItem({
        botId: this.activeBot.id,
        data: form,
      }).then(
        (res) => {
          globalMessageHandler("Successfully Updated.");
          this.isSaving = false;
        },
        (err) => {
          globalErrorHandler(err, "Error updating train history!");
          this.isSaving = false;
        }
      );
    },

    submitTrain(type) {
      var trainChat = null;

      if (type == this.TYPE_QNA) {
        trainChat = this.trainChatQnA;

        if (trainChat.question == "" || trainChat.answer == "") {
          return globalErrorHandler(
            "Error",
            "Please fill all required fields!"
          );
        }
      } else if (type == "text") {
        trainChat = this.trainChatText;

        if (trainChat.text == "") {
          return globalErrorHandler(
            "Error",
            "Please fill all required fields!"
          );
        }
      } else if (type == "url") {
        trainChat = this.trainChatUrl;

        if (trainChat.url == "") {
          return globalErrorHandler(
            "Error",
            "Please fill all required fields!"
          );
        }
      } else if (type == "file") {
        trainChat = this.trainChatFile;

        if (trainChat.file == null) {
          return globalErrorHandler(
            "Error",
            "Please fill all required fields!"
          );
        }
      }

      this.isSaving = true;

      this.createTrain({ botId: this.activeBot.id, data: trainChat }).then(
        (res) => {
          globalMessageHandler("Successfully Created.");

          this.emptyTrain(type);
          this.isSaving = false;
        },
        (err) => {
          globalErrorHandler(err, "Error submitting train!");
          this.isSaving = false;
        }
      );
    },

    emptyTrain(type = ''){
      if (type == this.TYPE_QNA){
        this.trainChatQnA = this.getNewTrainChat(type);
      }
      if (type == this.TYPE_TEXT){
        this.trainChatText = this.getNewTrainChat(type);
      }
      if (type == this.TYPE_FILE){
        this.trainChatFile = this.getNewTrainChat(type);
      }
      if (type == this.TYPE_URL){
        this.trainChatUrl = this.getNewTrainChat(type);
      }
    },
  },

  computed: {
    ...mapGetters("bots", ["activeBot"]),
    ...mapGetters("train", ["activeTypeHistory"]),

    isButtonDisabledQnA() {
      return !(this.trainChatQnA.question && this.trainChatQnA.answer)
    },

     isButtonDisabledText() {
      return !(this.trainChatText.text)
     },

     isButtonDisabledFile() {
      return !(this.trainChatFile.title && this.trainChatFile.file);
     },

     isButtonDisabledUrl() {
      return !(this.trainChatUrl.title && this.trainChatUrl.url);
     }
  },
};
</script>

<style lang="scss" scoped>
.about {
  background: white;
  padding: 15px;
}
</style>
