<template>
  <v-container>
    <loading v-if="convoLoading" overlayLoadingCenter overlay />
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filteredUsers"
          :search="search"
          :items-per-page="10"
          class="elevation-1 w-100"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Users List</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>

              <div class="d-flex">
                <!-- <v-text-field
                  v-if="!addingUser"
                  v-model="search"
                  label="Search User"
                  style="width: 270px"
                  outlined
                  dense
                  hide-details
                ></v-text-field> -->
                <v-text-field
                  v-if="addingUser"
                  v-model="newUserEmail"
                  label="New User Email"
                  style="width: 287px"
                  outlined
                  dense
                  hide-details
                  @click:append="addNewUser"
                  clearable
                  :disabled="isSending"
                  class="mr-2"
                ></v-text-field>
                <v-select
                  v-if="addingUser"
                  v-model="newUserRole"
                  :items="roleOptions"
                  label="Select Role"
                  item-text="text"
                  item-value="value"
                  outlined
                  hide-details
                  :disabled="isSending"
                  dense
                  style="width: 200px"
                ></v-select>
              </div>
              <v-divider
                class="mx-4"
                inset
                vertical
                v-if="addingUser"
              ></v-divider>
              <div class="d-flex">
                <v-btn
                  v-if="!addingUser"
                  @click="addUser"
                  color="primary"
                  :disabled="addingUser"
                >
                  Delegate New User
                </v-btn>
                <v-btn
                  v-if="addingUser"
                  :disabled="newUserEmail == null || newUserRole == null"
                  :loading="isSending"
                  @click="addNewUser"
                  color="primary"
                  class="mr-2"
                >
                  Send
                </v-btn>
                <v-btn v-if="addingUser" @click="cancelAddUser"> Cancel </v-btn>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-muted">{{ item.email }}</td>
              <td>
                <span
                  :class="
                    getStatusClass(item.validated ? 'Accepted' : 'Pending')
                  "
                  class="px-2 py-1 rounded font-weight-medium"
                >
                  {{ item.validated ? "Accepted" : "Pending" }}
                </span>
              </td>
              <td>
                <v-select
                  v-model="item.role_id"
                  :items="roleOptions"
                  label="Select Role"
                  item-text="text"
                  item-value="value"
                  outlined
                  hide-details
                  dense
                  style="width: 150px"
                  @change="updateInvitation(item, `update`)"
                ></v-select>
                <!-- <div class="ml-2">
                  <v-progress-circular
                    :size="30"
                    :width="3"
                    indeterminate
                    color="primary"
                    v-if="item.role_id === item.role_id && isUpdating"
                  ></v-progress-circular>
                </div> -->
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingComponent from "@/components/helpers/Loading";
export default {
  components: {
    LoadingComponent,
  },
  data() {
    return {
      search: "",
      sortByStatus: false,
      isSending: false,
      addingUser: false,
      isUpdating: false,
      newUserEmail: "",
      newUserRole: null,
      headers: [
        { text: "Email", value: "email" },
        { text: "Status", value: "validated", sortable: true },
        { text: "Role", value: "role_id" },
      ],
    };
  },
  computed: {
    ...mapGetters("bots", ["contacts", "activeBot"]),
    ...mapGetters("user", ["roles", "delegatedAccounts"]),

    roleOptions() {
      return this.roles.map((role) => ({
        text: role.name,
        value: role.id,
      }));
    },
    delegationsData() {
      return _.get(this.delegatedAccounts.data, "data", []);
    },
    filteredUsers() {
      const regex = new RegExp(this.search, "i");
      const users = this.delegationsData.filter((user) =>
        regex.test(user.email)
      );

      return users;
    },
  },
  methods: {
    ...mapActions("invite", ["sendInvite"]),

    updateInvitation(item, action) {
      this.isSending = true;

      if (action == "update") {
        this.isUpdating = true;
      }

      // send invite via email
      let _data = {
        email: item.email,
        role_id: item.role_id,
        bot: this.activeBot.id,
      };

      this.sendInvite(_data, this.activeBot)
        .then(
          (res) => {
            this.isSending = false;
            if (action == "add") {
              globalMessageHandler("Successfully invited your recipient.");
            } else {
              this.isUpdating = false;
              globalMessageHandler(
                "Successfully re-invited your recipient to a new role."
              );
            }
          },
          (err) => {
            globalErrorHandler(
              "",
              "Please enter a valid email address in the correct format"
            );
          }
        )
        .finally(() => {
          this.isSending = false;
          this.isUpdating = false;
          // Reset state
          this.addingUser = false;
          this.newUserEmail = "";
        });
    },
    addUser() {
      this.addingUser = true;
      this.newUserEmail = "";
      this.newUserRole = null;
      this.search = "";
    },

    cancelAddUser() {
      this.addingUser = false;
    },

    addNewUser() {
      // Validate and add the new user logic
      if (this.newUserEmail) {
        const newUser = {
          email: this.newUserEmail,
          validated: false, // Set initial status
          role_id: this.newUserRole,
        };

        // Add logic to save or send invitation for the new user
        this.updateInvitation(newUser, "add");
        this.delegatedAccounts.data.data.push(newUser);
      }
    },

    getStatusClass(status) {
      return status === "Accepted" ? "status-primary" : "status-tertiary";
    },
  },
};
</script>

<style lang="scss" scoped>
.status-primary {
  color: #fff;
  background: green;
}

.status-tertiary {
  color: #fff;
  background: orange;
}
</style>
