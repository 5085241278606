<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-chip-group
          v-if="emails.length > 0"
          v-model="selectedEmails"
          column
          multiple
        >
          <v-chip
            v-for="(email, index) in emails"
            :key="index"
            class=""
            color="primary"
            @click:close="removeEmail(email.id)"
            theme="primary"
          >
            {{ email.email }}
            <v-icon small class="ml-2" @click.stop="removeEmail(email.id)">
              mdi-close
            </v-icon>
          </v-chip>
        </v-chip-group>

        <v-text-field
          v-model="newEmail"
          label="Enter recipient email in the field and press enter"
          @keyup.enter="addContact"
          outlined
          clearable
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <hr class="mb-4" />

    <v-row>
      <v-col cols="12">
        <v-chip-group
          v-if="numbers.length > 0"
          v-model="selectedNumbers"
          column
          multiple
        >
          <v-chip
            v-for="(number, index) in numbers"
            :key="index"
            class=""
            color="tertiary"
            @click:close="removeNumber(number.id)"
          >
            {{ number.mobile }}
            <v-icon small class="ml-2" @click.stop="removeNumber(number.id)">
              mdi-close
            </v-icon>
          </v-chip>
        </v-chip-group>

        <v-text-field
          v-model="newNumber"
          label="Enter recipient number in the field and press enter"
          @keyup.enter="addContact"
          outlined
          clearable
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Utils from "@/helpers/utils";

export default {
  data() {
    return {
      selectedEmails: [],
      newEmail: null,

      selectedNumbers: [],
      newNumber: null,

      isSending: null,
    };
  },
  computed: {
    ...mapGetters("bots", ["contacts", "activeBot"]),

    numbers() {
      return this.contacts?.mobile_numbers;
    },

    emails() {
      return this.contacts?.emails;
    },

    extractedEmails() {
      return this.contacts?.emails.map((contact) => contact.email);
    },

    extractedNumbers() {
      return this.contacts?.mobile_numbers.map((contact) => contact.mobile);
    },
  },

  methods: {
    ...mapMutations("bots", ["REMOVE_CONTACT", "setContact"]),
    ...mapActions("bots", ["createContact", "deleteContact"]),

    async addContact() {
      try {
        if (this.newEmail || this.newNumber) {
          if (
            this.selectedEmails.indexOf(this.newEmail) === -1 ||
            this.selectedNumbers.indexOf(this.newNumber) === -1
          ) {
            this.selectedEmails.push(this.newEmail);
            this.selectedNumbers.push(this.newNumber);

            let _phoneData = {
              mobile_numbers: this.extractedNumbers,
              emails: this.extractedEmails,
            };

            if (this.newEmail) {
              _phoneData.emails = [this.newEmail];
            }

            if (this.newNumber) {
              _phoneData.mobile_numbers = [this.newNumber];
            }

            this.createContact({
              botId: this.activeBot.id,
              data: _phoneData,
            }).then(
              (res) => {
                let _setters = {
                  mobile_numbers: res.data.mobile_numbers,
                  emails: res.data.emails,
                };
                this.setContact(_setters);

                this.newEmail = "";
                this.newNumber = "";

                globalMessageHandler("Successfully invited your recipient.");
              },
              (err) => {
                globalErrorHandler(err, "Error adding contact");
              }
            );
          }
        }
      } catch (error) {
        globalErrorHandler(error, "Unable to save contact.");
      } finally {
      }
    },

    addEmail() {
      if (this.newEmail && this.selectedEmails.indexOf(this.newEmail) === -1) {
        this.selectedEmails.push(this.newEmail);
        this.emails.push(this.newEmail);
        this.newEmail = "";
      }
    },

    removeEmail(item) {
      Utils.showMessageDefault({
        html: `Are you sure you want to remove?`,
      }).then((res) => {
        if (res.isConfirmed) {
          let _data = {
            ids: [item],
          };

          this.removeContact({
            type: "email",
            _data,
          });
        }
      });
    },

    removeNumber(item) {
      Utils.showMessageDefault({
        html: `Are you sure you want to remove?`,
      }).then((res) => {
        if (res.isConfirmed) {
          let _data = {
            ids: [item],
          };

          this.removeContact({
            type: "mobile",
            _data,
          });
        }
      });
    },

    async removeContact(form) {
      try {
        await this.deleteContact(form).then(
          (res) => {
            globalMessageHandler("Successfully Deleted.");
            this.REMOVE_CONTACT(form);
          },
          (err) => {
            globalErrorHandler(err, "Error deleting email!");
          }
        );
      } catch (error) {
        globalErrorHandler(error, "Unable to delete email.");
      } finally {
      }
    },
  },
};
</script>
